<template>
  <div>
    <base-header class="pb-1">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Nova Assinatura</h6>
        </div>
      </div>
    </base-header>
  
    <div class="container-fluid mt--6">
      <form class="needs-validation" @submit.prevent="salvarAssinatura">
        <!-- Titular da Assinatura -->
        <card>
          <h3 slot="header" class="mb-0">Titular da Assinatura</h3>
          <div class="form-row">
            <div class="col-md-3">
              <base-input label="CPF" name="cpfcnpj" placeholder="CPF" required v-model="subscription.holder.cpfCnpj"
                v-mask="['###.###.###-##', '##.###.###/####-##']" @change="blockFields"></base-input>
            </div>
            <div class="col-md-5">
              <base-input label="Nome Completo / Razão Social" name="nomecompleto"
                placeholder="Nome Completo / Razão Social" required v-model="subscription.holder.name"></base-input>
            </div>
            <div class="col-md-3" v-show="isPF">
              <base-input label="Data de Nascimento" name="datadenascimento" placeholder="Data de Nascimento"
                v-model="subscription.holder.dateOfBirth" v-mask="'##/##/####'"></base-input>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-3">
              <base-input label="E-mail" name="email" placeholder="E-mail" v-model="subscription.holder.email"
                type="email"></base-input>
            </div>
            <div class="col-md-3">
              <base-input label="Tel Celular" name="celular" placeholder="Tel Celular" required
                v-model="subscription.holder.cellPhone" v-mask="['(##)#####-####']"></base-input>
            </div>
            <div class="col-md-3">
              <base-input label="Tel Res/Com" name="homePhone" placeholder="Tel Res/Com"
                v-model="subscription.holder.homePhone" v-mask="['(##)####-####', '(##)#####-####']"></base-input>
            </div>
            <div class="col-md-3">
              <label class="form-control-label">Sexo</label>
              <select class="form-control" v-model="subscription.holder.sex">
                <option value="MALE">Masculino</option>
                <option value="FEMALE">Feminino</option>
                <option value="NI">Não informado</option>
              </select>
            </div>
          </div>
          <div class="row" v-show="isPF">
            <div class="col-12">
              <div class="custom-switch ml-2">
                <input type="checkbox" v-model="subscription.holderOnlyResponsibleFinance" class="custom-control-input"
                  id="holderfinance" />
                <label class="custom-control-label" for="holderfinance">Titular é APENAS o responsável
                  financeiro</label>
              </div>
            </div>
          </div>
        </card>
  
        <div class="row">
          <!-- Endereco -->
          <div class="col-lg-6">
            <div class="card-wrapper">
              <card>
                <h3 slot="header" class="mb-0">Endereço</h3>
                <div class="row">
                  <div class="col-md-6">
                    <base-input append-icon="fas fa-map-marker" name="cep" label="CEP" placeholder="CEP" required
                      v-model="subscription.holder.zipcode" v-mask="'########'" @blur="validateZipcode"></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <base-input label="Endereço" name="endereco" placeholder="Endereço" required
                      v-model="subscription.holder.address"></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <base-input :disabled="isDisabled" label="Número *" name="numero" id="numero" required
                      placeholder="Número" v-model="subscription.holder.number" v-mask="['######']"></base-input>
                  </div>
                  <div class="col-md-1">
                    <div class="form-group">
                      <label class="form-control-label">S/N</label>
                      <div class="custom-switch">
                        <input type="checkbox" @change="semNumero($event)" class="custom-control-input" id="antigas" />
                        <label class="custom-control-label form-control-label" for="antigas"></label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <base-input label="Complemento" name="complemento" placeholder="Complemento"
                      v-model="subscription.holder.complementAddress"></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <base-input label="UF" name="uf" placeholder="UF" required
                      v-model="subscription.holder.uf"></base-input>
                  </div>
                  <div class="col-md-8">
                    <base-input label="Cidade" name="cidade" placeholder="Cidade" required
                      v-model="subscription.holder.city"></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <base-input label="Bairro" name="bairro" placeholder="Bairro" required
                      v-model="subscription.holder.neighborhood"></base-input>
                  </div>
                </div>
              </card>
            </div>
          </div>
  
          <!-- Informações do Plano -->
          <div class="col-lg-6">
            <div class="card-wrapper">
              <card>
                <h3 slot="header" class="mb-0">Informações da Assinatura</h3>
                <div class="row">
                  <div class="col-md-6" v-if="getProfile() == 'MANAGER' || getProfile() == 'SUPERVISOR' || getProfile() == 'ADMIN'
                                                      ">
                    <base-input label="Data do Contrato" @change="atribuiVencimento()" name="datadocontrato"
                      placeholder="Data do Contrato" v-model="subscription.dateBegin" v-mask="'##/##/####'"></base-input>
                  </div>
                  <div class="col-md-6">
                    <base-input :readonly="true" label="Dia de Vencimento" v-model="subscription.dayForPayment" required
                      name="diadevencimento">
                    </base-input>
                  </div>
  
                  <div class="col-md-12">
                    <base-input label="Plano" required name="plano">
                      <el-select v-model="subscription.planId" filterable placeholder="Plano"
                        @change="loadPaymentTypeByPlan">
                        <el-option v-for="option in plans" :key="option.id" :label="option.name" :value="option.id">
                          <span style="float: left">
                            {{ option.name }}
                            <span style="color: #8492a6; font-size: 10px">Adesão {{ option.accessionFeeFmt }} / Titular
                              {{ option.priceHolderFmt }} / Dependente
                              {{ option.priceDependentFmt }}</span>
                          </span>
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-md-12">
                    <base-input label="Forma de Pagamento" required name="formapagamento">
                      <el-select v-model="subscription.paymentType" placeholder="Forma de Pagamento">
                        <el-option v-for="option in paymentTypes" :key="option.label" :label="option.label"
                          :value="option.value"></el-option>
                      </el-select>
                    </base-input>
                  </div>
  
                  <div class="col-md-12" v-show="subscription.paymentType == 'DEBIT_CARD' ||
                                                        subscription.paymentType == 'CREDIT_CARD'
                                                        ">
                    <base-input class="input-group-alternative mb-3" required name="installments">
                      <el-select v-model="subscription.installments" placeholder="numero de parcelas">
                        <template v-for="plan of plans">
                          <template v-if="plan.id == subscription.planId">
                            <el-option v-for="option of installmentsLabels(plan)" :key="option.label"
                              :label="option.label" :value="option.value"></el-option>
                          </template>
                        </template>
                      </el-select>
                    </base-input>
  
                    <div class="custom-switch ml-2 mb-2">
                      <input type="checkbox" v-model="subscription.creditCard.choice" class="custom-control-input"
                        id="chkCard" />
                      <label class="custom-control-label" for="chkCard"><strong>
                          Solicitar preenchimento ao cliente</strong></label>
                    </div>
  
                    <card type="gradient-primary" v-show="subscription.creditCard.choice == false">
                      <div class="col-md-12">
                        <h4 class="text-white">
                          <span class="ni ni-money-coins pr-3"></span> Dados do
                          Cartão
                        </h4>
                      </div>
                      <div class="mt-4">
                        <base-input class="input-group-alternative mb-3" required name="bandeiracartao">
                          <el-select v-model="subscription.creditCard.brand" placeholder="Bandeira do cartão">
                            <el-option v-for="option in brands" :key="option.label" :label="option.label"
                              :value="option.value"></el-option>
                          </el-select>
                        </base-input>
  
                        <base-input prepend-icon="ni ni-single-02" class="input-group-alternative mb-3"
                          placeholder="Nome como está no cartão" name="nomecartao"
                          v-model="subscription.creditCard.name"></base-input>
  
                        <base-input prepend-icon="ni ni-credit-card" class="input-group-alternative mb-3"
                          placeholder="Número do cartão" name="numerocartao" v-model="subscription.creditCard.number"
                          v-mask="['####.####.####.####']"></base-input>
  
                        <div class="row">
                          <div class="col-6">
                            <base-input prepend-icon="ni ni-calendar-grid-58" class="input-group-alternative mb-3"
                              placeholder="MM/AAAA" name="validadecartao" v-model="subscription.creditCard.validate"
                              v-mask="['##/####']"></base-input>
                          </div>
                          <div class="col-6">
                            <base-input prepend-icon="ni ni-lock-circle-open" class="input-group-alternative mb-3"
                              placeholder="CVV" name="cvvcartao" v-model="subscription.creditCard.securityCode"
                              v-mask="['####']"></base-input>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                  <div class="col-md-12" v-if="getProfile() != 'BROKER'">
                    <base-input label="Corretor">
                      <el-select v-model="subscription.brokerId" placeholder="Corretor" name="corretor">
                        <el-option v-for="option in brokers" :key="option.name" :label="option.name"
                          :value="option.id"></el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-md-12" v-if="getProfile() != 'BROKER'">
                    <base-input label="Filial">
                      <el-select v-model="subscription.companyId" placeholder="Filial" name="filial">
                        <el-option v-for="option in companies" :key="option.name" :label="option.name"
                          :value="option.id"></el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
  
        <!-- Pets >
                                            <card>
                                              	<div class="row">
                                    				<div class="col-md-6">
                                    					<h3 class="mb-0">Pet</h3>
                                    				</div>
                                    				<div class="col-md-6 text-right mb-2">
                                    					<el-tooltip content="Adicionar Pet" placement="top">
                                    						<base-button type="neutral" icon size="sm" @click="openModalPet">
                                    						<span class="btn-inner--icon">
                                    							<i class="fas fa-user-edit"></i>
                                    						</span>
                                    						<span class="btn-inner--text">Adicionar</span>
                                    						</base-button>
                                    					</el-tooltip>
                                    				</div>
                                    			</div>
                                    			<div class="form-row">
                                    				<el-table
                                    					class="table-responsive align-items-center table-flush"
                                    					empty-text="Nenhum Pet adicionado"
                                    					header-row-class-name="thead-light"
                                    					:data="subscription.pets">
                                    
                                    					<el-table-column label="Quantidade" prop="quantity"></el-table-column>
                                    
                                    					<el-table-column label="Tipo do Pet" prop="description" sortable />
                                    
                                    					<el-table-column min-width="50px" align="center">
                                    						<div slot-scope="scope" class="table-actions">
                                    							<el-tooltip :content="'Remover '+ subscription.pets[scope.$index].description" placement="top">
                                    								<a href="#!"
                                    									@click.prevent="subscription.pets.splice(scope.$index, 1)"
                                    									class="table-action table-action-delete"
                                    									data-toggle="tooltip"
                                    									data-original-title="Remover">
                                    									<i class="fas fa-trash"></i>
                                    								</a>
                                    							</el-tooltip>
                                    						</div>
                                    					</el-table-column>
                                    				</el-table>
                                    			</div>
                                    		</card -->
  
        <!-- Dependentes -->
        <card>
          <div class="row">
            <div class="col-md-6">
              <h3 class="mb-0">Dependentes</h3>
            </div>
            <div class="col-md-6 text-right mb-2">
              <el-tooltip content="Adicionar Dependente" placement="top">
                <base-button type="neutral" icon size="sm" @click="modals.modalDependent = true">
                  <span class="btn-inner--icon">
                    <i class="fas fa-user-edit"></i>
                  </span>
                  <span class="btn-inner--text">Adicionar</span>
                </base-button>
              </el-tooltip>
            </div>
          </div>
          <div class="form-row">
            <el-table class="table-responsive align-items-center table-flush" empty-text="Nenhum dependente adicionado"
              header-row-class-name="thead-light" :data="subscription.dependents">
              <el-table-column label="Nome" prop="name" min-width="140px" sortable></el-table-column>
  
              <el-table-column label="CPF" prop="cpf" />
  
              <el-table-column label="Telefone" prop="phone" />
  
              <el-table-column label="Sexo" align="center" min-width="45px">
                <div slot-scope="{ row }">
                  <div v-if="row.sex == 'MALE'">
                    <el-tooltip content="Masculino" placement="top">
                      <span>M</span>
                      <span>M</span>
                    </el-tooltip>
                  </div>
                  <div v-if="row.sex == 'FEMALE'">
                    <el-tooltip content="Feminino" placement="top">
                      <span>F</span>
                      <span>F</span>
                    </el-tooltip>
                  </div>
                  <div v-if="row.sex == 'NI'">
                    <el-tooltip content="Não Informado" placement="top">
                      <span>-</span>
                    </el-tooltip>
                  </div>
                </div>
              </el-table-column>
  
              <el-table-column label="Parentesco" prop="kinship" />
  
              <el-table-column min-width="50px" align="center">
                <div slot-scope="scope" class="table-actions">
                  <el-tooltip content="Remover Dependente" placement="top">
                    <a href="#!" @click.prevent="
                                                          subscription.dependents.splice(scope.$index, 1)
                                                          " class="table-action table-action-delete"
                      data-toggle="tooltip" data-original-title="Remover">
                      <i class="fas fa-trash"></i>
                    </a>
                  </el-tooltip>
                </div>
              </el-table-column>
            </el-table>
          </div>
        </card>
  
        <!-- Modal Dependentes -->
        <modal :show.sync="modals.modalDependent">
          <h6 slot="header" class="modal-title" id="modal-title-default">
            Adição de Dependente
          </h6>
          <div class="row p-0">
            <div class="col-md-12">
              <base-input name="depnome" placeholder="Nome Completo" v-model="dependent.name"></base-input>
            </div>
            <div class="col-md-6">
              <base-input placeholder="CPF" name="cpfdep" v-model="dependent.cpf" v-mask="'###.###.###-##'"></base-input>
            </div>
            <div class="col-md-6">
              <base-input placeholder="Data de Nascimento" name="nascimentodep" v-model="dependent.dateOfBirth"
                v-mask="'##/##/####'"></base-input>
            </div>
            <div class="col-md-12">
              <base-input placeholder="E-mail" name="emaildep" v-model="dependent.email"></base-input>
            </div>
            <div class="col-md-12">
              <base-input placeholder="Telefone" name="phone" v-mask="['(##)#####-####']"
                v-model="dependent.phone"></base-input>
            </div>
            <div class="col-md-6">
              <select class="form-control" name="sex" v-model="dependent.sex">
                <option value="MALE">Masculino</option>
                <option value="FEMALE">Feminino</option>
                <option value="NI">Não informado</option>
              </select>
            </div>
            <div class="col-md-6">
              <select class="form-control" name="kinship" v-model="dependent.kinship">
                <option value="">Parentesco</option>
                <option value="Pai">Pai</option>
                <option value="Mãe">Mãe</option>
                <option value="Avô">Avô</option>
                <option value="Avó">Avó</option>
                <option value="Filho/Filha">Filho/Filha</option>
                <option value="Tio/Tia">Tio/Tia</option>
                <option value="Irmão/Irmã">Irmão/Irmã</option>
                <option value="Sobrinho">Sobrinho</option>
                <option value="Neto">Neto</option>
                <option value="O">Outros</option>
              </select>
            </div>
            <div class="col-md-12 mt-4" v-if="dependent.kinship == 'O'">
              <base-input maxlength="30" placeholder="Outro parentesco" name="kinshipAlt"
                v-model="dependent.kinshipAlt"></base-input>
            </div>
          </div>
  
          <template slot="footer">
            <div class="mt-0">
              <base-button type="primary" @click="addDependent">Adicionar Dependente</base-button>
              <base-button type="link" class="ml-auto" @click="modals.modalDependent = false">Cancelar</base-button>
            </div>
          </template>
        </modal>
  
        <!-- Modal Pet -->
        <modal :show.sync="modals.modalPet">
          <h6 slot="header" class="modal-title" id="modal-title-default">
            Adição de Pet
          </h6>
          <div class="row">
            <div class="col-md-4">
              <base-input placeholder="Quantidade" name="quantidade" v-model="pet.quantity" v-mask="'###'"></base-input>
            </div>
            <div class="col-md-8">
              <el-select v-model="pet.type" placeholder="Tipo do Pet">
                <el-option v-for="option in typesPet" :key="option.description" :label="option.description"
                  :value="option.description"></el-option>
              </el-select>
            </div>
          </div>
  
          <template slot="footer">
            <base-button type="primary" @click="addPet">Adicionar Pet</base-button>
            <base-button type="link" class="ml-auto" @click="modals.modalPet = false">Cancelar</base-button>
          </template>
        </modal>
  
        <div class="col-md-12 text-center mb-3">
          <base-button id="btnAssinatura" type="primary" :disable="beginSaveProcess" @click="simulate">Solicitar
            Assinatura</base-button>
        </div>
  
        <!-- Modal simulate -->
        <modal :show.sync="openModalSimulate">
          <h1 class="text-center">Confirmação de Valores</h1>
          <h3>
            Valor da primeira fatura:
            <b>{{ simulateResult.totalAmountFmt }}</b>
            <br />
          </h3>
          <p>Detalhes da primeira fatura:</p>
          <ul>
            <li>
              + {{ simulateResult.accessionFeeFmt }}
              <span style="color: #8492a6; font-size: 10px">(taxa de adesão)</span>
            </li>
            <li>
              + {{ simulateResult.proRataAmountFmt }}
              <span style="color: #8492a6; font-size: 10px">(pró-rata de {{ simulateResult.quantityDays }} dias -
                {{ simulateResult.dataBegin }} a
                {{ simulateResult.dataEnd }})</span>
            </li>
            <li>
              <b>
                = {{ simulateResult.totalAmountFmt }}
                <span style="color: #8492a6; font-size: 10px">(valor total da primeira fatura)</span>
              </b>
            </li>
          </ul>
          <div class="col-md-12 text-center mt-3">
            <base-button id="btnAssinaturaModal" type="primary" native-type="submit"
              :disabled="beginSaveProcess">Confirmar</base-button>
            <button type="button" class="btn btn-secundary ml-4" @click="closeSimulate">
              Desistir
            </button>
          </div>
        </modal>
      </form>
    </div>
    <!--- fecha conteudo -->
  </div>
</template>
<script>
import { Select, Option } from "element-ui";
import { Table, TableColumn, Tooltip } from "element-ui";
import { load } from "recaptcha-v3";
import Vue from "vue";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  created() {
    load("6LeDu_IpAAAAAGawg8nR3SFA_ZYpSwsC71Ub1f_U").then((instance) => {
      Vue.prototype.$recaptcha = instance;
    });

    this.loadPlans();
    this.loadBrokers();
    this.loadCompanies();
    this.dataHojeFormatada();
  },
  data() {
    return {
      simulateResult: {
        proRataAmountFmt: null,
        accessionFeeFmt: null,
        totalAmountFmt: null,
        dataBegin: null,
        dataEnd: null,
        quantityDays: 0,
      },
      isDisabled: false,
      openModalSimulate: false,
      isPF: true,
      beginSaveProcess: false,
      modals: {
        modalDependent: false,
        modalPet: false,
      },
      plans: [],
      dependent: {
        name: null,
        cpf: null,
        dateOfBirth: null,
        email: null,
        phone: "",
        sex: "MALE",
        kinship: "",
        kinshipAlt: "",
      },
      typesPet: [],
      pet: {
        quantity: null,
        type: null,
      },
      brands: [
        { label: "Visa", value: "VISA" },
        { label: "MasterCard", value: "MASTERCARD" },
        { label: "Elo", value: "ELO" },
        { label: "American", value: "AMEX" },
        { label: "Hipercard", value: "HIPERCARD" },
        { label: "Outros", value: "OUTROS" },
      ],
      paymentTypes: [],
      paymentDays: [
        { label: "Dia 5", value: "5" },
        { label: "Dia 10", value: "10" },
        { label: "Dia 15", value: "15" },
        { label: "Dia 20", value: "20" },
        { label: "Dia 25", value: "25" },
      ],
      brokers: [],
      companies: [],
      validated: false,
      subscription: {
        dateBegin: null,
        brokerId: null,
        companyId: null,
        planId: null,
        dayForPayment: null,
        paymentType: null,
        dependents: [],
        pets: [],
        installments: 1,
        creditCard: {
          choice: true,
          brand: null,
          number: null,
          validate: null,
          name: null,
          securityCode: null,
        },
        type: "PF",
        holderOnlyResponsibleFinance: false,
        holder: {
          id: null,
          cpfCnpj: "",
          name: "",
          dateOfBirth: "",
          sex: "MALE",
          email: "",
          cellPhone: "",
          homePhone: "",
          zipcode: "",
          address: "",
          complementAddress: "",
          uf: "",
          city: "",
          neighborhood: "",
          isHolder: true,
        },
      },
    };
  },
  methods: {
    dataHojeFormatada() {
      var data = new Date();
      var dia = data.getDate() < 10 ? "0" + data.getDate() : data.getDate();
      var mes =
        data.getMonth() + 1 < 10
          ? "0" + (data.getMonth() + 1)
          : data.getMonth() + 1;
      var ano = data.getFullYear();
      this.subscription.dateBegin = [dia, mes, ano].join("/");
      this.subscription.dayForPayment = dia;
    },
    simulate() {
      console.log(this.subscription);
      this.$clubApi
        .post("/subscription/invoice/simulate", this.subscription)
        .then((response) => {
          this.simulateResult = response.data.object;
          this.openModalSimulate = true;
        })
        .catch((error) => {
          this.$notify({ type: "warning", message: error.response.data.msg });
        })
        .finally(() => {
          NProgress.done();
        });
    },
    openModalPet() {
      //this.loadPets();
      this.modals.modalPet = true;
    },
    closeSimulate() {
      this.openModalSimulate = false;
    },
    blockFields($event) {
      if ($event.target.value.length > 14) {
        this.isPF = false;
        this.subscription.holderOnlyResponsibleFinance = true;
      } else {
        this.isPF = true;
        this.subscription.holderOnlyResponsibleFinance = false;
      }
    },
    getProfile() {
      return localStorage.getItem("profile");
    },
    salvarAssinatura() {
      try {
        this.$validator.validateAll();
      } finally {
        this.validated = true;
      }

      if (this.validated) {
        document.getElementById("btnAssinaturaModal").disabled = true;
        document.getElementById("btnAssinatura").disabled = true;
        this.openModalSimulate = false;
        this.beginSaveProcess = true;
        this.$recaptcha.execute("newsubscriptionpf").then((token) => {
          const request = {
            recaptchaResponse: token,
            ...this.subscription,
          };

          this.$clubApi
            .put("/subscription", request)
            .then((response) => {
              location.href = "/assinatura/" + response.data.object.id;
            })
            .catch((error) => {
              if (error.response.data.msg.indexOf("Erro Club100") < 0) {
                document.getElementById("btnAssinaturaModal").disabled = false;
                document.getElementById("btnAssinatura").disabled = false;
              }
              console.log(error.response.data);
              this.$notify({
                type: "warning",
                message: error.response.data.msg,
              });
            })
            .finally(() => {
              this.beginSaveProcess = false;
              NProgress.done();
            });
        });
      }
    },
    loadPlans() {
      this.$clubApi
        .get("/plan/list/active/PF")
        .then((response) => {
          this.plans = response.data.object;
        })
        .catch(() => {
          this.$notify({
            type: "warning",
            message: "Erro ao carregar listagem de planos disponiveis.",
          });
        })
        .finally(() => {
          NProgress.done();
        });
    },
    loadBrokers() {
      this.$clubApi
        .get("/broker")
        .then((response) => {
          this.brokers = response.data.object;
        })
        .catch(() => {
          this.$notify({
            type: "warning",
            message: "Erro ao carregar listagem de corretores disponiveis.",
          });
        });
    },
    loadCompanies() {
      this.$clubApi
        .get("/company")
        .then((response) => {
          this.companies = response.data.object;
        })
        .catch(() => {
          this.$notify({
            type: "warning",
            message: "Erro ao carregar listagem de companias disponiveis.",
          });
        })
        .finally(() => {
          NProgress.done();
        });
    },
    addDependent() {
      let kinshipPreenchido = this.dependent.kinship;
      if (this.dependent.kinship === "O") {
        kinshipPreenchido = this.dependent.kinshipAlt;
      }
      this.subscription.dependents.push({
        name: this.dependent.name,
        cpf: this.dependent.cpf,
        dateOfBirth: this.dependent.dateOfBirth,
        email: this.dependent.email,
        phone: this.dependent.phone,
        sex: this.dependent.sex,
        kinship: kinshipPreenchido,
      });
      this.dependent = {
        name: "",
        cpf: "",
        dateOfBirth: "",
        email: "",
        phone: "",
        sex: "MALE",
        kinship: "",
        kinshipAlt: "",
      };
      this.modals.modalDependent = false;
    },
    addPet() {
      let tamFiltro = this.subscription.pets.filter((pet) => {
        return pet.type === this.pet.type;
      }).length;
      if (tamFiltro > 0) {
        this.$notify({
          type: "warning",
          message: "Já existe esse tipo adicionado na lista.",
        });
      } else {
        this.subscription.pets.push({
          quantity: this.pet.quantity,
          description: this.pet.type,
        });
        this.pet = {
          quantity: null,
          type: null,
        };
        this.modals.modalPet = false;
      }
    },
    validateZipcode() {
      var zipcode = this.subscription.holder.zipcode.replace("-", "");

      if (zipcode == "") {
        this.subscription.holder.zipcode = "";
        this.subscription.holder.address = "";
        this.subscription.holder.uf = "";
        this.subscription.holder.city = "";
        this.subscription.holder.neighborhood = "";
        this.subscription.holder.complementAddress = "";
      } else {
        this.$clubApi
          .get("/utils/zipcode/" + zipcode)
          .then((response) => {
            this.subscription.holder.address = response.data.object.logradouro;
            this.subscription.holder.uf = response.data.object.uf;
            this.subscription.holder.city = response.data.object.localidade;
            this.subscription.holder.neighborhood = response.data.object.bairro;
          })
          .catch((error) => {
            this.subscription.holder.zipcode = "";
            this.subscription.holder.address = "";
            this.subscription.holder.uf = "";
            this.subscription.holder.city = "";
            this.subscription.holder.neighborhood = "";
            this.subscription.holder.complementAddress = "";
            this.$notify({ type: "warning", message: error.response.data.msg });
          })
          .finally(() => {
            NProgress.done();
          });
      }
    },
    installmentsLabels(plan) {
      let min = plan.minInstallments || 1;
      let max = plan.maxInstallments || 12;
      let competence = 1;

      switch (plan.competenceType) {
        case 'YEAR':
          competence = 12 * (plan.competence || 1);
          break;
        default:
          competence = plan.competence || 1;
          break;
      }

      if (competence < max) {
        max = competence;
      }

      if (min === 1) {
        min++;
      }

      const value = plan.priceHolder + (plan.priceDependent * this.subscription.dependents.length);

      const createLabel = (installment) => {
        let label = `${installment}x de ${(value / installment).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`;

        if (plan.accessionFee > 0) {
          label += ` (Primeira cobrança ${((value + plan.accessionFee) / installment).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })})`;
        }

        return label;
      };

      const labels = [{ label: createLabel(1), value: 1 }];

      for (let i = min; i <= max; i++) {
        labels.push({ label: createLabel(i), value: i });
      }

      return labels;
    },
    loadPaymentTypeByPlan() {
      this.$clubApi
        .get("/plan/" + this.subscription.planId + "/list/payments/object")
        .then((response) => {
          this.paymentTypes = response.data.object;
        })
        .catch((error) => {
          this.$notify({ type: "warning", message: error.response.data.msg });
        })
        .finally(() => {
          NProgress.done();
        });
    },
    loadPets() {
      this.$clubApi
        .get("/subscription/pet/list")
        .then((response) => {
          this.typesPet = response.data.object;
        })
        .catch((error) => {
          this.$notify({ type: "warning", message: error.response.data.msg });
        })
        .finally(() => {
          NProgress.done();
        });
    },

    atribuiVencimento() {
      var data = new Date(
        this.subscription.dateBegin.split("/").reverse().join("/")
      );
      if (!isNaN(data)) {
        var dia = data.getDate() < 10 ? "0" + data.getDate() : data.getDate();
        this.subscription.dayForPayment = dia;
      } else {
        this.subscription.dateBegin = "";
        this.subscription.dayForPayment = "";
      }
    },

    semNumero: function (event) {
      let el = document.getElementById("numero");
      if (document.getElementById("antigas").checked) {
        this.isDisabled = true;
        el.value = "0";
        el.dispatchEvent(new Event("input"));
      } else {
        this.isDisabled = false;
        el.value = "";
        el.dispatchEvent(new Event("input"));
      }
    },
  },
};
</script>
<style></style>
